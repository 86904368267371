@font-face {
  font-family: 'Muli';
  src: local('Muli'), url('./fonts/Muli.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src: local('Muli'), url('./fonts/Muli-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli'), url('./fonts/Muli-ExtraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src: local('Muli'), url('./fonts/Muli-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Muli';
  src: local('Muli'), url('./fonts/Muli-BoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: local('Muli'),
    url('./fonts/Muli-ExtraLightItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
* :not(.material-icons) {
  font-family: Muli !important;
}
body {
  margin: 0;
  padding: 0;
}

.MuiButtonBase-root {
  padding: 6px 16px;
}

.MuiAccordionSummary-root {
  display: flex;
}
