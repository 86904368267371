.boxLogin {
  width: 500px;
  padding: 0 !important;
}
.btnBack {
  width: 30%;
  align-self: center;
  border-radius: 40px;
  background: transparent;
  color: #919191;
  font-size: 17px;
}
.btnLink {
  margin-top: 10px;
  border-radius: 20px;
  color: rgb(63, 107, 215);
}
.btnSubmit {
  height: 70px;
  border-radius: 40px;
  background: transparent;
  color: #919191;
  font-size: 20px;
  border: 2px solid #efefef;
  align-self: center;
}
.content {
  margin: 0 30px;
  display: flex;
}
.field {
  width: 100%;
  margin-bottom: 20px;
}
.fieldCheckbox {
  margin: 0;
}
.formContainer {
  margin: 30px;
  height: 600px;
}
.footer {
  background: #1e2125;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  z-index: 999;
  left: 0;
  font-size: 12px;
  color: #fff;
}
.grid {
  flex-grow: 1;
}
.gridBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gridCheckbox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.header {
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  box-shadow: 0 0 1em #c8c8c8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  z-index: 2;
}
.link {
  cursor: pointer;
}
.logoHeader {
  width: 60px;
}
.paper {
  width: 600px;
  margin: 40px auto;
}
.rootChange {
  background: #ededed;
  color: black;
  display: flex;
  justify-content: center;
  padding: 100px;
  flex-grow: 1;
  min-height: 100vh;
}
.rootLogin {
  width: 100%;
  background: #ededed;
  color: black;
  display: flex;
  justify-content: center;
  padding: 100px;
  flex-grow: 1;
  min-height: 100vh;
}
.rootRecovery {
  background: #ededed;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-star;
  align-items: flex-star;
  padding: 100px;
  min-height: 100vh;
}
.tabsMobile {
  width: 100%;
  margin: 10px;
  background: #fff;
}
.textHead {
  font-size: 30px;
  padding: 0;
}

@media only screen and (max-width: 501px) {
  .rootLogin {
    padding: 0;
  }
}
